.App {
  text-align: center;
  background-color: black;
  color: white;
  padding-top: 1%;
}
/*
@font-face {
  font-family: "Disco";
  src: local("FiraSans"), url(./fonts/Disco3.ttf) format("truetype");
}
*/
header {
  display: flex;
  margin-left: 5%;
}
.videoPlayer {
  margin-top: 5%;
}
h1 {
  color: red;
  font-size: 50px;
  padding-top: 4%;
  margin: 0;
  width: 100%;
}
.list {
  display: flex;
  flex-wrap: wrap;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 5%;
  padding-bottom: 400px;
}

.list > div {
  flex: 0 0 48%;
  margin: 5% 4% 0 0;
}

.list > div:nth-child(2n) {
  margin-right: 0%;
}
.list > div img {
  width: 100%;
}

.list > div p {
  font-size: 22px;
  margin-top: 15px;
  text-align: left;
}

.App .video-react .video-react-big-play-button {
  position: absolute;
  top: 46%;
  left: 46%;
}
